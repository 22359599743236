<template>
    <Layout>
        <div class="informacoes-container1">
            <div class="informacoes-container2">
                <div class="informacoes-container3">
                    <!-- <label class="informacoes-text">{{ nome }}</label> -->
                    <h2 class="informacoes-text01">{{ $t_("informacoes") }}</h2>
                </div>
                <div class="informacoes-container4">
                    <v-list color="transparent" light dense>
                        <v-list-item
                            v-for="(item, index) in navegacao"
                            :key="index"
                            @click="getLink(item)"
                            v-once
                            class="informacoes-text04"
                        >
                            {{ item.title }}
                        </v-list-item>
                    </v-list>
                </div>
            </div>
            <div class="informacoes-container5 elevation-0 mt-5" v-if="faq">
                <v-expansion-panels multiple class="elevation-0">
                    <v-expansion-panel
                        v-for="(item, i) in $config.faq"
                        :key="i"
                        class="elevation-0"
                    >
                        <v-expansion-panel-header>
                            <big v-html="item.pergunta"></big>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-4">
                            <div v-html="item.resposta"></div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <div class="informacoes-container5" v-else>
                <h1
                    v-html="$config.conteudo.titulo"
                    class="informacoes-text10"
                ></h1>
                <span
                    v-html="$config.conteudo.html"
                    class="informacoes-text11"
                ></span>
            </div>
        </div>
    </Layout>
</template>

<script>
export default {
    name: "Informacoes",
    components: {
        Layout: () => import("@/layout/Default.vue"),
    },
    data() {
        return {
            navegacao: [
                {
                    id: 1,
                    title: this.$t_("perguntas_frequentes"),
                    icon: "",
                    link: "perguntas-frequentes",
                },
                // {
                //     id: 2,
                //     title: this.$t_('decreto'),
                //     icon: '',
                //     link: 'decreto'
                // }
            ],
            faq: false,
        };
    },
    methods: {
        toTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        },
        getLink(item) {
            this.$router.push({ path: item.link });
        },
        getRouter() {
            let rota =
                document.location.href.indexOf("#") == -1
                    ? document.location.pathname.split("/")[2]
                    : document.location.href.split("#")[1].split("/")[2];
            if (this.$config.dados.multilinguagem) {
                rota = document.location.pathname.split("/")[3];
            }
            switch (rota) {
                case "decreto":
                    this.$config.getConteudo("decreto");
                    break;
                case "perguntas-frequentes":
                    this.$config.getSacPerguntas();
                    this.faq = true;
                    break;
                default:
                    this.$axios.rota("Error");
            }
        },
    },
    mounted() {
        this.getRouter();
        const self = this;
        setTimeout(() => {
            self.toTop();
        }, 1500);
    },
};
</script>

<style scoped>
big {
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
}
.informacoes-container {
    width: 100%;
    height: auto;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.informacoes-container1 {
    flex: 0 0 auto;
    width: 940px;
    min-height: 400px;
    display: flex;
    padding: 15px;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
}

.informacoes-container2 {
    flex: 0 0 auto;
    width: 300px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.informacoes-container3 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: 10px;
    align-items: flex-start;
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: flex-start;
}

.informacoes-text {
    color: var(--dl-color-gray-500);
    font-size: 12px;
}

.informacoes-text01 {
    color: var(--dl-color-gray-500);
    font-size: px;
}

.informacoes-container4 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: 10px;
    align-items: flex-start;
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: flex-start;
}

.informacoes-text02 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 14px;
    padding-top: 8px;
    border-color: var(--dl-color-secundaria);
    border-width: 1px;
    padding-left: 10px;
    margin-bottom: 5px;
    padding-right: 0px;
    padding-bottom: 8px;
    border-top-width: 0px;
    border-left-width: 3px;
    border-right-width: 0px;
    border-bottom-width: 0px;
}

.informacoes-text04 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 14px;
    padding-top: 8px;
    border-color: var(--dl-color-secundaria);
    border-width: 1px;
    padding-left: 10px;
    margin-bottom: 5px;
    padding-right: 0px;
    padding-bottom: 8px;
    border-top-width: 0px;
    border-left-width: 3px;
    border-right-width: 0px;
    border-bottom-width: 0px;
}

.informacoes-text06 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 14px;
    padding-top: 8px;
    border-color: var(--dl-color-secundaria);
    border-width: 1px;
    padding-left: 10px;
    margin-bottom: 5px;
    padding-right: 0px;
    padding-bottom: 8px;
    border-top-width: 0px;
    border-left-width: 3px;
    border-right-width: 0px;
    border-bottom-width: 0px;
}

.informacoes-text08 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 14px;
    padding-top: 8px;
    border-color: var(--dl-color-secundaria);
    border-width: 1px;
    padding-left: 10px;
    margin-bottom: 5px;
    padding-right: 0px;
    padding-bottom: 8px;
    border-top-width: 0px;
    border-left-width: 3px;
    border-right-width: 0px;
    border-bottom-width: 0px;
}

.informacoes-container5 {
    flex: 0 0 auto;
    width: calc(100% - 300px);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.informacoes-text10 {
    margin-bottom: 15px;
}

.informacoes-text11 {
    color: var(--dl-color-gray-200);
    font-size: 14px;
}

@media (max-width: 991px) {
    .informacoes-container1 {
        width: 100%;
        display: block;
    }
}

@media (max-width: 767px) {
    .informacoes-container3 {
        width: 50%;
    }

    .informacoes-container4 {
        width: 100%;
    }

    .informacoes-container5 {
        width: 100%;
    }

    .informacoes-text10 {
        font-size: 22px;
        align-self: center;
    }
}

@media (max-width: 479px) {
    .informacoes-container3 {
        width: 100%;
    }

    .informacoes-container4 {
        width: 100%;
    }
}
</style>
